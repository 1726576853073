import { Fragment, useState, useEffect } from "react";
import { TitleHeader } from "../../components";
import { notification, Button } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import * as AppApi from "../../api/sistema";
import { getPeriodo } from "../../utils/functions";
import LineGraphic from "./LineGraphic";

const idPeriodo = getPeriodo()?.idperiodo || 0;

function Home() {
  const [dashboard, setDashboard] = useState({
    totalPreinscritos: 0,
    preinscritosValidados: 0,
    preinscritosSinValidar: 0,
    porcentajeValidacion: 0,
    porcentajeHombres: 0,
    porcentajeMujeres: 0
  });
  const [reload, setReload] = useState(false);

  const fetchDataToPage = async () => {
    try {
      AppApi.executeQuery({
        query: "get_data_dashboard_detallado",
        variables: [
          { name: "IDPERIODO1", value: idPeriodo },
          { name: "IDPERIODO2", value: idPeriodo },
          { name: "IDPERIODO3", value: idPeriodo },
          { name: "IDPERIODO4", value: idPeriodo },
          { name: "IDPERIODO5", value: idPeriodo },
          { name: "IDPERIODO6", value: idPeriodo }
        ]
      }).then((data) => {
        const {
          preinscritos = "0",
          validados = "0",
          sin_validar = "0",
          porcentaje_hombres = "0",
          porcentaje_mujeres = "0",
          porcentaje_validacion = "0"
        } = data.payload[0];

        setDashboard({
          totalPreinscritos: parseInt(preinscritos, 10),
          preinscritosValidados: parseInt(validados, 10),
          preinscritosSinValidar: parseInt(sin_validar, 10),
          porcentajeValidacion: parseFloat(porcentaje_validacion),
          porcentajeHombres: parseFloat(porcentaje_hombres),
          porcentajeMujeres: parseFloat(porcentaje_mujeres)
        });
      });
    } catch (error) {
      notification.error({
        message: "Problemas con la solicitud",
        description: error.message
      });
    }
  };

  const getValidationColor = (percentage) => {
    if (percentage >= 85) return "text-green-500 dark:text-green-400";
    if (percentage >= 40) return "text-yellow-500 dark:text-yellow-600";
    return "text-red-500 dark:text-red-400";
  };

  useEffect(() => {
    fetchDataToPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <Fragment>
      <TitleHeader
        title={
          <span className="flex gap-2 items-center">
            <h1 className="font-bold">Estadísticas</h1>
            <Button
              size="small"
              icon={<ReloadOutlined />}
              onClick={() => setReload(!reload)}
            ></Button>
          </span>
        }
      />
      <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4">
        <div className="min-w-0 rounded-lg border-4 border-gray-200 shadow-xs overflow-hidden bg-white dark:bg-gray-800">
          <div className="p-4 flex items-center">
            <div className="p-3 rounded-full text-blue-500 dark:text-blue-100 bg-blue-100 dark:bg-blue-500 mr-4">
              <svg fill="currentColor" viewBox="0 0 20 20" className="w-5 h-5">
                <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
              </svg>
            </div>
            <div>
              <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                Total Pre-inscritos
              </p>
              <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                {dashboard.totalPreinscritos}
              </p>
            </div>
          </div>
        </div>

        <div className="min-w-0 rounded-lg border-4 border-gray-200 shadow-xs overflow-hidden bg-white dark:bg-gray-800">
          <div className="p-4 flex items-center">
            <div className="p-3 rounded-full text-purple-500 dark:text-purple-100 bg-purple-100 dark:bg-purple-500 mr-4">
              <svg fill="currentColor" viewBox="0 0 20 20" className="w-5 h-5">
                <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
              </svg>
            </div>
            <div>
              <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                % Postulantes Validados
              </p>
              <p
                className={`text-lg font-semibold ${getValidationColor(
                  dashboard.porcentajeValidacion
                )}`}
              >
                {dashboard.porcentajeValidacion.toFixed(1)}%
              </p>
            </div>
          </div>
        </div>

        <div className="min-w-0 rounded-lg border-4 border-gray-200 shadow-xs overflow-hidden bg-white dark:bg-gray-800">
          <div className="p-4 flex items-center">
            <div className="p-3 rounded-full text-green-500 dark:text-green-100 bg-green-100 dark:bg-green-500 mr-4">
              <svg fill="currentColor" viewBox="0 0 20 20" className="w-5 h-5">
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <div>
              <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                Pre-inscritos Validados
              </p>
              <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                {dashboard.preinscritosValidados}
              </p>
            </div>
          </div>
        </div>

        <div className="min-w-0 rounded-lg border-4 border-gray-200 shadow-xs overflow-hidden bg-white dark:bg-gray-800">
          <div className="p-4 flex items-center">
            <div className="p-3 rounded-full text-red-500 dark:text-red-100 bg-red-100 dark:bg-red-500 mr-4">
              <svg fill="currentColor" viewBox="0 0 20 20" className="w-5 h-5">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <div>
              <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                Sin Validar
              </p>
              <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                {dashboard.preinscritosSinValidar}
              </p>
            </div>
          </div>
        </div>

        {/* <div className="min-w-0 rounded-lg border-4 border-gray-200 shadow-xs overflow-hidden bg-white dark:bg-gray-800">
          <div className="p-4 flex items-center">
            <div className="p-3 rounded-full text-pink-500 dark:text-pink-100 bg-pink-100 dark:bg-pink-500 mr-4">
              <svg fill="currentColor" viewBox="0 0 20 20" className="w-5 h-5">
                <path
                  fillRule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <div>
              <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">Género</p>
              <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                Masculino: {dashboard.porcentajeHombres.toFixed(1)}% | Femenino:{" "}
                {dashboard.porcentajeMujeres.toFixed(1)}%
              </p>
            </div>
          </div>
        </div> */}
      </div>

      <div className="w-full flex flex-col md:flex-row"></div>

      <div className="w-full flex flex-col md:flex-row">
        <div className="w-full h-96">
          <LineGraphic />
        </div>
      </div>
    </Fragment>
  );
}

export default Home;
