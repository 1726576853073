import { useEffect, useState } from "react";
import moment from "moment";
import { ResponsiveLine } from "@nivo/line";
import * as AppApi from "../../api/sistema";
import { getPeriodo } from "../../utils/functions";

export default function LineGraphic() {
  const [isLoading, setIsLoading] = useState(true);
  const [jsonData, setJsonData] = useState({
    id: "inscripciones",
    data: []
  });

  const fetchTendencia = async () => {
    try {
      const idPeriodo = getPeriodo()?.idperiodo || 0;
      const response = await AppApi.executeQuery({
        query: "get_tendencia_inscripciones_periodo",
        variables: [{ name: "IDPERIODO", value: idPeriodo }]
      });

      setJsonData({
        id: "inscripciones",
        data: response.payload.map((item) => ({
          x: item.fecha,
          y: parseInt(item.cantidad)
        }))
      });
    } catch (error) {
      console.error("Error al obtener tendencia:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTendencia();
  }, []);

  if (isLoading) return <div>Cargando gráfica...</div>;

  return (
    <ResponsiveLine
      data={[jsonData]}
      colors={{ scheme: "category10" }}
      margin={{
        top: 50,
        right: 110,
        bottom: 50,
        left: 60
      }}
      xScale={{
        type: "time",
        format: "%Y-%m-%d"
      }}
      yScale={{
        type: "linear"
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        format: "%b %d",
        tickValues: "every days",
        tickSize: 0,
        tickPadding: 10,
        legendOffset: 45,
        legend: "Fecha concurrida",
        legendPosition: "middle"
      }}
      axisLeft={{
        orient: "left",
        tickSize: 0,
        tickPadding: 16,
        tickRotation: 0,
        legendOffset: -45,
        legendPosition: "middle",
        legend: "Nro de postulantes"
      }}
      curve="linear"
      enableGridX={false}
      useMesh
      enablePointLabel={true}
      pointLabel={(point) => `${point.y}`}
      pointSize={8}
      pointColor="#ffffff"
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      tooltip={({ point }) => (
        <div className="bg-white p-3 shadow-lg rounded-lg border">
          <div className="font-semibold text-gray-800">{moment(point.data.x).format("LL")}</div>
          <div className="text-blue-600">{point.data.y} postulantes</div>
        </div>
      )}
      theme={{
        tooltip: {
          container: {
            background: "white"
          }
        },
        labels: {
          text: {
            fontSize: 11,
            fill: "#333",
            fontWeight: "bold"
          }
        }
      }}
    />
  );
}
