import { useState, useEffect } from "react";
import { Form, Input, message, Button, Select } from "antd";
import * as AppApi from "../../api/sistema";
import {
  getDataCatalogo,
  openInNewTab,
  generaMensajeObservacionWhatsapp
} from "../../utils/functions";
import { TIPO_CATALOGO, ALTERNATIVES_SI_NO, OBJ_ROLES } from "../../utils/shared";
import { WhatsAppOutlined } from "@ant-design/icons";
import { useAuth } from "../../utils/useAuth";

const { Option } = Select;

const DatosPersonales = ({ record, isDisabled }) => {
  const [tieneDiscapacidad, setTieneDiscapacidad] = useState(record?.tienediscapacidad);
  const { rol } = useAuth();

  const [catalogo, setCatalogo] = useState({
    preparacionuniversitaria: [],
    relacionparentesco: [],
    modalidadingreso: [],
    medioinformacion: [],
    deporte: [],
    lugarbiometrico: []
  });
  const [statePostulacion, setStatePostulacion] = useState({
    idpreparacionuniversitaria: record?.idpreparacionuniversitaria,
    idmodalidadingreso: record?.idmodalidadingreso,
    idmedioinformacion: record?.idmedioinformacion,
    idtipodeporte: record?.idtipodeporte
  });

  useEffect(() => {
    fetchCatalogo();
  }, []);

  const fetchCatalogo = async () => {
    const preparacionuniversitaria = await getDataCatalogo(TIPO_CATALOGO.preparacionuniversitaria);
    const relacionparentesco = await getDataCatalogo(TIPO_CATALOGO.relacionparentesco);
    const medioinformacion = await getDataCatalogo(TIPO_CATALOGO.medioinformacion);
    const deporte = await getDataCatalogo(TIPO_CATALOGO.deporte);
    const lugarbiometrico = await getDataCatalogo(TIPO_CATALOGO.lugarbiometrico);
    const modalidadingreso = await AppApi.executeQuery({
      query: "get_catalogo_by_tipo_like",
      variables: [{ name: "TIPO", value: TIPO_CATALOGO.modalidadingreso, type: "string" }]
    });

    setCatalogo({
      preparacionuniversitaria,
      relacionparentesco,
      medioinformacion,
      deporte,
      lugarbiometrico,
      modalidadingreso: modalidadingreso.payload || []
    });
  };

  const onFinish = (values) => {
    sendDataToServer(values);
    onFormSubmit2();
  };

  // ------- Conexion con api -------
  const sendDataToServer = async (data) => {
    const params = {
      nombretabla: "ope_entidad",
      nombreid: "identidad",
      valorid: record?.identidad
    };

    data["tienediscapacidad"] = tieneDiscapacidad === 1;

    AppApi.executeUpdate(params, data)
      .then((res) => {
        message.success(res.message);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const onFormSubmit2 = () => {
    const params = {
      nombretabla: "pos_postulante",
      nombreid: "idpostulante",
      valorid: record?.idpostulante
    };

    AppApi.executeUpdate(params, statePostulacion)
      .then(() => {})
      .catch((err) => {
        message.error(err.message);
      });
  };

  return (
    <Form layout="vertical" initialValues={{ remember: true }} onFinish={onFinish}>
      <h1 className="text-azul-700 font-semibold text-base mb-2">Datos del apoderado</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
        <Form.Item
          label="Nombres y apellidos"
          name="apoderado"
          initialValue={record?.apoderado}
          rules={[
            {
              required: true,
              message: "El campo es requerido"
            }
          ]}
        >
          <Input disabled={isDisabled} />
        </Form.Item>

        <Form.Item
          label={
            <p>
              Celular{" "}
              <Button
                size="small"
                disabled={!record.apoderadocelular}
                onClick={() =>
                  openInNewTab(
                    generaMensajeObservacionWhatsapp({
                      celular: record.apoderadocelular,
                      nombre: record.nombre,
                      apellidopaterno: record.apellidopaterno,
                      apellidomaterno: record.apellidomaterno,
                      documentoregistro: record.documentoregistro,
                      observacionexterno: record.observacionexterno
                    })
                  )
                }
              >
                <WhatsAppOutlined />
              </Button>
            </p>
          }
          name="apoderadocelular"
          initialValue={record?.apoderadocelular}
          rules={[
            {
              required: true,
              message: "El campo es requerido"
            }
          ]}
        >
          <Input disabled={isDisabled} />
        </Form.Item>

        <Form.Item
          label="Correo electrónico"
          name="apoderadoemail"
          initialValue={record?.apoderadoemail}
          rules={[
            {
              required: true,
              message: "El campo es requerido"
            }
          ]}
        >
          <Input disabled={isDisabled} />
        </Form.Item>

        <Form.Item
          label="Tipo de relación"
          name="idparentesco"
          initialValue={record?.idparentesco || ""}
        >
          <Select disabled={isDisabled}>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.relacionparentesco.map((item) => (
              <Option value={item.id}>{item.label}</Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <hr className="mt-4" />
      <h1 className="text-azul-700 font-semibold text-base mb-2">Datos del padre</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
        <Form.Item label="Nombres y apellidos" name="padre" initialValue={record?.padre}>
          <Input disabled={isDisabled} />
        </Form.Item>

        <Form.Item label="Celular" name="padrecelular" initialValue={record?.padrecelular}>
          <Input disabled={isDisabled} />
        </Form.Item>

        <Form.Item label="Correo electrónico" name="padreemail" initialValue={record?.padreemail}>
          <Input disabled={isDisabled} />
        </Form.Item>
      </div>

      <hr className="mt-4" />
      <h1 className="text-azul-700 font-semibold text-base mb-2">Datos de la madre</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
        <Form.Item label="Nombres y apellidos" name="madre" initialValue={record?.madre}>
          <Input disabled={isDisabled} />
        </Form.Item>

        <Form.Item label="Celular" name="madrecelular" initialValue={record?.madrecelular}>
          <Input disabled={isDisabled} />
        </Form.Item>

        <Form.Item label="Correo electrónico" name="madreemail" initialValue={record?.madreemail}>
          <Input disabled={isDisabled} />
        </Form.Item>
      </div>

      <hr className="mt-4" />
      <h1 className="text-azul-700 font-semibold text-base mb-2">Información complementaria</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
        <Form.Item
          label="Lugar de preparación"
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select
            disabled={isDisabled}
            value={statePostulacion.idpreparacionuniversitaria}
            onSelect={(value) => {
              setStatePostulacion({ ...statePostulacion, idpreparacionuniversitaria: value });
            }}
          >
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.preparacionuniversitaria.map((item) => (
              <Option value={item.id}>{item.label}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Modalidad">
          <Select
            disabled={isDisabled}
            value={statePostulacion.idmodalidadingreso}
            onSelect={(value) => {
              setStatePostulacion({ ...statePostulacion, idmodalidadingreso: value });
            }}
          >
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.modalidadingreso.map((item) => (
              <Option value={item.id}>{item.label}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Medio por el cual me enteré">
          <Select
            disabled={isDisabled}
            value={statePostulacion.idmedioinformacion}
            onSelect={(value) => {
              setStatePostulacion({ ...statePostulacion, idmedioinformacion: value });
            }}
          >
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.medioinformacion.map((item) => (
              <Option value={item.id}>{item.label}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Tengo discapacidad">
          <Select
            disabled={isDisabled}
            value={tieneDiscapacidad}
            onSelect={(value) => {
              setTieneDiscapacidad(value);
            }}
          >
            <Option value="" disabled>
              Seleccione
            </Option>
            {ALTERNATIVES_SI_NO.map((item) => (
              <Option value={item.id}>{item.label}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Número CONADIS" name="numeroconadis" initialValue={record?.numeroconadis}>
          <Input disabled={isDisabled} />
        </Form.Item>

        <Form.Item label="Deporte que practica">
          <Select
            disabled={isDisabled}
            value={statePostulacion.idtipodeporte}
            onSelect={(value) => {
              setStatePostulacion({ ...statePostulacion, idtipodeporte: value });
            }}
          >
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.deporte.map((item) => (
              <Option value={item.id}>{item.label}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Lugar de Registro Biométrico"
          name="idlugarbiometrico"
          initialValue={record?.idlugarbiometrico || ""}
        >
          <Select
            disabled={
              rol !== OBJ_ROLES.SUPERADMIN &&
              rol !== OBJ_ROLES.ADMINISTRADOR &&
              rol !== OBJ_ROLES.ESPECIALISTA_SISTEMAS
            }
          >
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.lugarbiometrico.map((item) => (
              <Option value={item.id}>{item.label}</Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="float-right my-4">
          Guardar cambios
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DatosPersonales;
