import { Fragment, useState, useEffect } from "react";
import { TitleHeader, Loading, UserCard, UploadDeleteDocument, Adjuntado } from "../../components";
import { useParams } from "react-router-dom";
import { ReloadOutlined, ExclamationCircleOutlined, ScissorOutlined } from "@ant-design/icons";
import { Button, Radio, Modal, Tabs, message, Form, Input, Checkbox } from "antd";
import { ESTADO, LABEL_ESTADO, TIPO_REQUISITO, INSTITUCION, OBJ_ROLES } from "../../utils/shared";
import { getPeriodo } from "../../utils/functions";
import IMAGE_ATTARCHMENT from "../../assets/attachment.png";
import AES from "crypto-js/aes";

import DatosPersonales from "./RDatosPersonales";
import DatosAcademicos from "./RDatosAcademicos";
import DatosComplementarios from "./RDatosComplementarios";
import DatosPago from "./RDatosPago";
import RDatosProfesionales from "./RDatosProfesionales";
import RDatosFinales from "./RDatosFinales";

import * as AppApi from "../../api/sistema";
import { viewFileByUrl } from "../../utils/functions";
import { useAuth } from "../../utils/useAuth";
import RProcesarFotografia from "./RProcesarFotografia";
import { useHistory } from "react-router-dom";

const { confirm } = Modal;
const idPeriodo = getPeriodo()?.idperiodo || 0;
const idInstitucion = getPeriodo()?.idinstitucion || 0;

const Revision = () => {
  const { usuario, rol } = useAuth();
  const history = useHistory();

  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [fotoPostulante, setFotoPostulante] = useState("");
  const [requisitoFotografia, setRequisitoFotografia] = useState(null);

  const [dataRequisitosPersona, setDataRequisitosPersona] = useState([]);
  const [dataRequisitosPostulacion, setDataRequisitosPostulacion] = useState([]);

  const [requisitoXPersona, setRequisitoXPersona] = useState([]);
  const [requisitoXPostulacion, setRequisitoXPostulacion] = useState([]);

  const [dataToPage, setDataToPage] = useState({});
  const [dataCarne, setDataCarne] = useState(null);
  const [dataFichaBiometrico, setDataFichaBiometrico] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const { idpostulante } = useParams();

  useEffect(() => {
    fetchDataToPage();
    fetchRequisitosEntidad();
    fetchRequisitoByPostulante(idpostulante, idPeriodo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const fetchDataToPage = async () => {
    setIsLoading(true);

    AppApi.executeQuery({
      query: "get_total_informacion_postulante",
      variables: [{ name: "IDPOSTULANTE", value: idpostulante }]
    })
      .then((data) => {
        const item = data.payload[0];

        console.log(item);

        setDataToPage(item);
        fetchRequisitosEntidad(item?.identidad);
        fetchRequisitos(idPeriodo, item?.idmodalidadingreso);
        fetchDataCarne(item?.identidad, idPeriodo);
        fetchDataFichaBiometrico(item?.identidad);
        setFotoPostulante(`/${item?.identidad}/${item?.documentoregistro}_FTR.jpg`);

        setIsDisabled(
          item.idestado === ESTADO.aceptado ||
            item.idestado === ESTADO.llenadovpi ||
            item.idestado === ESTADO.inscrito ||
            item.idestado === ESTADO.aceptadobiometrico
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchRequisitos = async (idperiodo, idmodalidadingreso) => {
    AppApi.executeQuery({
      query: "get_requisitos_by_periodo",
      variables: [
        { name: "IDPERIODO", value: idperiodo },
        { name: "IDMODALIDADINGRESO", value: idmodalidadingreso }
      ]
    })
      .then((data) => {
        const requisitopersona = data.payload.filter(
          (i) => i.idtiporequisito === TIPO_REQUISITO.persona
        );
        setDataRequisitosPersona(requisitopersona);

        const requisitopostulacion = data.payload.filter(
          (i) => i.idtiporequisito === TIPO_REQUISITO.postulante
        );

        setDataRequisitosPostulacion(requisitopostulacion);

        const requisitoFoto = data.payload.filter((i) => i.abreviatura === "FTR");
        setRequisitoFotografia(requisitoFoto[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchRequisitosEntidad = async (identidad) => {
    try {
      AppApi.executeQuery({
        query: "get_requisito_x_entidad",
        variables: [{ name: "IDENTIDAD", value: identidad, type: "string" }]
      }).then((data) => {
        setRequisitoXPersona(data.payload);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchRequisitoByPostulante = async (idpostulante, idperiodo) => {
    AppApi.executeQuery({
      query: "get_requisito_x_periodo_and_postulante",
      variables: [
        { name: "IDPOSTULANTE", value: idpostulante },
        { name: "IDPERIODO", value: idperiodo }
      ]
    })
      .then((data) => {
        setRequisitoXPostulacion(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchDataCarne = async (identidad, idperiodo) => {
    AppApi.executeQuery({
      query: "get_impresion_ficha_preinscripcion",
      variables: [
        { name: "IDENTIDAD", value: identidad, type: "string" },
        { name: "IDPERIODO", value: idperiodo }
      ]
    }).then((data) => {
      const item = data.payload[0];

      if (item) {
        item.image =
          process.env.REACT_APP_BASE_PATH +
          "/api/core/panel/preview/" +
          identidad +
          "/" +
          item?.documentoregistro +
          "_FTR.jpg";
      }

      if (item) {
        const ciphertext = AES.encrypt(JSON.stringify(item), "secretToken").toString();
        setDataCarne(encodeURIComponent(ciphertext));
      }
    });
  };

  const fetchDataFichaBiometrico = async (identidad) => {
    AppApi.executeQuery({
      query: "get_impresion_ficha_biometrico",
      variables: [{ name: "IDENTIDAD", value: identidad, type: "string" }]
    }).then((data) => {
      const item = data.payload[0];
      if (item) {
        const ciphertext = AES.encrypt(JSON.stringify(item), "secretToken").toString();
        setDataFichaBiometrico(encodeURIComponent(ciphertext));
      }
    });
  };

  const downloadFile = (filepath) => {
    return new Promise((resolve) => {
      resolve(AppApi.previewFile(filepath));
    });
  };

  const showChangeStateConfirm = (newEstadoId) => {
    confirm({
      title: `¿Estás seguro que deseas cambiar el estado a ${LABEL_ESTADO[newEstadoId]}?`,
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        const params = {
          nombretabla: "pos_postulante",
          nombreid: "idpostulante",
          valorid: dataToPage?.idpostulante
        };

        // Validación previa para el estado "inscrito"
        if (newEstadoId === ESTADO.inscrito && !dataToPage.idprogramaestudio) {
          message.error("No existe un programa de estudio seleccionado");
          return;
        }

        try {
          const res = await AppApi.executeUpdate(params, {
            idestado: newEstadoId,
            usuariovalido: usuario.usuario,
            fechavalido: new Date()
          });

          message.success(res.message);

          if (newEstadoId === ESTADO.aceptado) {
            // Volver a "aceptado" requiere quitar el código de postulante
            quitaCodigoPostulante();
            executeAceptadoEvent();
          } else if (newEstadoId === ESTADO.inscrito) {
            // Cambiar a "inscrito" requiere generar o asignar el código de postulante
            generaCodigoPostulante();
            executeInscritoEvent();
          } else {
            // Lógica para otros estados, si es necesario
            quitaCodigoPostulante();
          }

          // Recargar después de procesar todos los eventos
          window.location.reload();
        } catch (err) {
          message.error(err.message);
        }
      },
      onCancel() {
        return null;
      }
    });
  };

  const executeAceptadoEvent = () => {
    AppApi.executePost("/api/core/validado", {
      idpostulante: dataToPage?.idpostulante,
      identidad: dataToPage?.identidad
    })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const executeInscritoEvent = () => {
    AppApi.executePost("/api/core/genera-codigo", {
      idpostulante: dataToPage?.idpostulante,
      idperiodo: idPeriodo,
      idprogramaestudio: dataToPage.idprogramaestudio
    })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const quitaCodigoPostulante = () => {
    const params = {
      nombretabla: "pos_postulante",
      nombreid: "idpostulante",
      valorid: dataToPage?.idpostulante
    };

    AppApi.executeUpdate(params, {
      codigo: ""
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const generaCodigoPostulante = () => {
    AppApi.executePost("/api/core/genera-codigo", {
      idpostulante: dataToPage?.idpostulante,
      idperiodo: idPeriodo,
      idprogramaestudio: dataToPage.idprogramaestudio
    })
      .then(() => {
        fetchDataToPage();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeAttachedStateEntidad = async (identidadrequisito, newState) => {
    const params = {
      nombretabla: "ope_entidadrequisito",
      nombreid: "identidadrequisito",
      valorid: identidadrequisito
    };

    AppApi.executeUpdate(params, { ok: newState })
      .then((res) => {
        message.success(res.message);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const changeAttachedStatePostulante = async (idpostulanterequisito, newState) => {
    const params = {
      nombretabla: "pos_postulanterequisito",
      nombreid: "idpostulanterequisito",
      valorid: idpostulanterequisito
    };

    AppApi.executeUpdate(params, { ok: newState })
      .then((res) => {
        message.success(res.message);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const onChangeFotoRecortada = (e) => {
    const params = {
      nombretabla: "ope_entidad",
      nombreid: "identidad",
      valorid: dataToPage?.identidad
    };

    AppApi.executeUpdate(params, { fotorecortada: e.target.checked })
      .then((res) => {
        message.success(res.message);
        setDataToPage({ ...dataToPage, fotorecortada: e.target.checked });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const showModalProcesarFotografia = () => {
    setIsModalVisible(true);
    setModalContent(
      <RProcesarFotografia
        fotografia={viewFileByUrl(fotoPostulante)}
        title={`${dataToPage?.apellidopaterno} ${dataToPage?.apellidomaterno}, ${dataToPage?.nombre}`}
        requisito={requisitoFotografia}
        identidad={dataToPage?.identidad}
        documentoregistro={dataToPage?.documentoregistro}
        reload={reload}
        setReload={setReload}
        requisitoXPersona={requisitoXPersona}
      />
    );
  };

  const onDeletePostulacion = async () => {
    Modal.confirm({
      title: `¿ESTÁS SEGURO DE ELIMINAR LA POSTULACIÓN?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Sí, eliminar",
      cancelText: "Cancelar",
      async onOk() {
        try {
          const params = {
            nombretabla: "pos_postulante",
            nombreid: "idpostulante",
            valorid: dataToPage?.idpostulante
          };

          AppApi.executeUpdate(params, { eliminado: true })
            .then(() => {
              message.success("La postulanción ha sido eliminada satisfactoriamente");
            })
            .catch((err) => {
              message.error(err.message);
            });

          history.replace("/app/postulante");
          window.location.reload();
        } catch (err) {
          message.error(err.message);
        }
      }
    });
  };

  const onSubmit = (values) => {
    const params = {
      nombretabla: "pos_postulante",
      nombreid: "idpostulante",
      valorid: dataToPage?.idpostulante
    };

    AppApi.executeUpdate(params, values)
      .then((res) => {
        message.success(res.message);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Fragment>
      <TitleHeader
        title={
          <div className="flex gap-2 items-center">
            <span className="font-bold text-lg">REVISIÓN DE DOCUMENTOS</span>

            <span className="flex items-center justify-center">
              <Button
                size="small"
                icon={<ReloadOutlined />}
                onClick={() => setReload(!reload)}
              ></Button>
            </span>
          </div>
        }
        extra={
          <div className="mt-2 mr-6 font-bold text-base flex gap-2">
            <span>POSTULANTE</span>
            {" | "}
            <h3>{dataToPage?.periodo}</h3>
          </div>
        }
      />

      <div className="lg:flex w-full">
        <div className="w-full mb-8 md:w-1/2 md:mx-auto lg:w-2/12 text-center">
          <h2 className="text-md font-bold mb-2 text-azul-700">FOTOGRAFÍA</h2>
          {dataToPage?.identidad && dataToPage?.documentoregistro && (
            <UserCard
              image={viewFileByUrl(fotoPostulante)}
              title={`${dataToPage?.apellidopaterno} ${dataToPage?.apellidomaterno}, ${dataToPage?.nombre}`}
              subTitle={`${dataToPage?.tipodocumentoregistro}: ${dataToPage?.documentoregistro}`}
              state={dataToPage?.idestado}
              codigo={
                ((rol === OBJ_ROLES.SUPERADMIN || rol === OBJ_ROLES.ADMINISTRADOR) &&
                  dataToPage?.codigo &&
                  "CÓDIGO: " + dataToPage?.codigo) ||
                (dataToPage?.idestado === ESTADO.inscrito && (
                  <Button type="link" onClick={() => generaCodigoPostulante()}>
                    Generar código
                  </Button>
                )) ||
                ""
              }
              biometrico={dataToPage?.realizobiometrico}
            />
          )}
          {(rol === OBJ_ROLES.SUPERADMIN || rol === OBJ_ROLES.ADMINISTRADOR) &&
            (dataToPage?.idestado === ESTADO.inscrito ||
              dataToPage?.idestado === ESTADO.aceptado ||
              dataToPage?.idestado === ESTADO.aceptadobiometrico) && (
              <>
                <iframe
                  width="300"
                  height="40"
                  src={`${process.env.REACT_APP_BASE_CREDENTIAL_PATH}/biometrico?token=${dataFichaBiometrico}`}
                  title="Descarga Ficha Registro Biométrico"
                ></iframe>

                <iframe
                  width="300"
                  height="40"
                  src={`${process.env.REACT_APP_BASE_CREDENTIAL_PATH}/fase1?token=${dataCarne}`}
                  title="Descarga Carne de postulante"
                ></iframe>
              </>
            )}
          <hr className="my-4" />

          <div className="text-left">
            <Checkbox checked={dataToPage?.fotorecortada} onChange={onChangeFotoRecortada}>
              Fotografía recortada
            </Checkbox>

            {!dataToPage?.fotorecortada && (
              <Button
                className="mt-2"
                icon={<ScissorOutlined />}
                onClick={showModalProcesarFotografia}
              >
                Procesar fotografía
              </Button>
            )}
          </div>

          <hr className="my-4" />
          {dataRequisitosPersona.map((item, index) => (
            <UploadDeleteDocument
              key={index}
              title={item.denominacion}
              action="/api/core/panel/upload/requisito/entidad"
              documentoregistro={dataToPage?.documentoregistro}
              abreviatura={item.abreviatura}
              formato={item.formato}
              idrequisito={item.idrequisito}
              identidad={dataToPage?.identidad}
              idtiporequisito={item.idtiporequisito}
              reload={reload}
              setReload={setReload}
            />
          ))}
          {dataRequisitosPostulacion.map((item, index) => (
            <UploadDeleteDocument
              key={index}
              title={item.denominacion}
              action="/api/core/panel/upload/requisito/postulante"
              documentoregistro={dataToPage?.documentoregistro}
              abreviatura={item.abreviatura}
              formato={item.formato}
              idrequisito={item.idrequisito}
              identidad={dataToPage?.identidad}
              idperiodorequisito={item.idperiodorequisito}
              idpostulante={idpostulante}
              idtiporequisito={item.idtiporequisito}
              reload={reload}
              setReload={setReload}
            />
          ))}

          <hr />

          {(rol === OBJ_ROLES.ADMINISTRADOR ||
            rol === OBJ_ROLES.SUPERADMIN ||
            rol === OBJ_ROLES.ESPECIALISTA_SISTEMAS) && (
            <Button
              type="link"
              className="mt-3"
              block
              danger
              disabled={isDisabled}
              onClick={onDeletePostulacion}
            >
              Eliminar postulación
            </Button>
          )}
        </div>

        <div className="w-full mb-8 lg:w-7/12 lg:px-10">
          <h2 className="text-md font-bold mb-4 text-azul-700">DATOS DEL POSTULANTE</h2>

          {(idInstitucion === INSTITUCION.DIAD || idInstitucion === INSTITUCION.VIAC) && (
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="PERSONALES" key="1">
                <DatosPersonales record={dataToPage} isDisabled={isDisabled} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="ACADÉMICOS" key="2">
                <DatosAcademicos record={dataToPage} isDisabled={isDisabled} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="COMPLEMENTARIOS" key="3">
                <DatosComplementarios record={dataToPage} isDisabled={isDisabled} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="FINALES" key="4">
                <DatosPago record={dataToPage} isDisabled={isDisabled} />
              </Tabs.TabPane>
            </Tabs>
          )}

          {idInstitucion === INSTITUCION.CEPU && (
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="PERSONALES" key="1">
                <DatosPersonales record={dataToPage} isDisabled={isDisabled} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="ACADÉMICOS" key="2">
                <DatosAcademicos record={dataToPage} isDisabled={isDisabled} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="COMPLEMENTARIOS" key="3">
                <DatosComplementarios record={dataToPage} isDisabled={isDisabled} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="FINALES" key="6">
                <RDatosFinales record={dataToPage} isDisabled={isDisabled} />
              </Tabs.TabPane>
            </Tabs>
          )}

          {idInstitucion === INSTITUCION.SESP && (
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="PERSONALES" key="1">
                <DatosPersonales record={dataToPage} isDisabled={isDisabled} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="PROFESIONALES" key="5">
                <RDatosProfesionales record={dataToPage} isDisabled={isDisabled} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="COMPLEMENTARIOS" key="3">
                <DatosComplementarios record={dataToPage} isDisabled={isDisabled} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="FINALES" key="6">
                <RDatosFinales record={dataToPage} isDisabled={isDisabled} />
              </Tabs.TabPane>
            </Tabs>
          )}
        </div>

        <div className="w-full lg:w-3/12">
          <h2 className="text-md font-bold mb-4 text-azul-700">ARCHIVOS ADJUNTADOS</h2>
          {/*=======================================================
          ================== for de requisitos =====================
          =======================================================*/}

          {/* Ejemplo: DNI, fotografía */}
          <div className="mb-4">
            {dataRequisitosPersona.length > 0 &&
              dataRequisitosPersona.map((item) => {
                // Encontrar el requisito correspondiente en requisitoXPersona
                const requisitoCorrespondiente = requisitoXPersona.find(
                  (req) => req.idrequisito === item.idrequisito
                );

                return (
                  <Adjuntado
                    key={item.idrequisito}
                    miniatura={requisitoCorrespondiente?.isvalor === "1" ? IMAGE_ATTARCHMENT : ""}
                    title={item.denominacion}
                    filepath={requisitoCorrespondiente ? `/${requisitoCorrespondiente.ruta}` : ""}
                    isFileOk={requisitoCorrespondiente?.ok}
                    downloadFileName={`${dataToPage?.documentoregistro}_${item.abreviatura}.${item.formato}`}
                    onDownload={() =>
                      downloadFile(
                        requisitoCorrespondiente ? `/${requisitoCorrespondiente.ruta}` : ""
                      )
                    }
                    valorid={requisitoCorrespondiente?.identidadrequisito}
                    isDisabled={isDisabled}
                    changeAttachedState={changeAttachedStateEntidad}
                  />
                );
              })}

            {/* Ejemplo: voucher, transferencia de pago */}

            {dataRequisitosPostulacion.length > 0 &&
              dataRequisitosPostulacion.map((item) => {
                // Encontrar el requisito correspondiente en requisitoXPostulacion
                const requisitoCorrespondiente = requisitoXPostulacion.find(
                  (req) => req.idrequisito === item.idrequisito
                );

                return (
                  <Adjuntado
                    key={item.idrequisito}
                    miniatura={requisitoCorrespondiente?.isvalor === "1" ? IMAGE_ATTARCHMENT : ""}
                    title={item.denominacion}
                    filepath={requisitoCorrespondiente ? `/${requisitoCorrespondiente.ruta}` : ""}
                    isFileOk={requisitoCorrespondiente?.ok}
                    downloadFileName={`${dataToPage?.documentoregistro}_${item.abreviatura}.${item.formato}`}
                    onDownload={() =>
                      downloadFile(
                        requisitoCorrespondiente ? `/${requisitoCorrespondiente.ruta}` : ""
                      )
                    }
                    valorid={requisitoCorrespondiente?.idpostulanterequisito}
                    isDisabled={isDisabled}
                    changeAttachedState={changeAttachedStatePostulante}
                  />
                );
              })}
          </div>

          {/* ---------- ACTUALIZAR ESTADO ---------- */}

          <hr className="my-2" />
          <h2 className="text-md font-bold my-2 text-azul-700">ACTUALIZAR ESTADO</h2>

          {idInstitucion === INSTITUCION.DIAD && (
            <Radio.Group
              className="flex flex-wrap text-center"
              buttonStyle="solid"
              value={dataToPage?.idestado}
              onChange={(e) => showChangeStateConfirm(e.target.value)}
            >
              {rol !== OBJ_ROLES.ORGANIZADOR && (
                <Radio.Button value={ESTADO?.incompleto || ""} className="">
                  INCOMPLETO
                </Radio.Button>
              )}
              <Radio.Button value={ESTADO?.pendiente || ""} className="">
                PENDIENTE
              </Radio.Button>
              <Radio.Button value={ESTADO?.observado || ""} className="">
                OBSERVADO
              </Radio.Button>
              <Radio.Button value={ESTADO?.aceptadobiometrico || ""} className="">
                ACEPTADO-BIOMÉTRICO
              </Radio.Button>
              <Radio.Button value={ESTADO?.aceptado || ""} className="">
                ACEPTADO-VPI
              </Radio.Button>

              {rol === OBJ_ROLES.SUPERADMIN && (
                <Radio.Button value={ESTADO?.inscrito || ""} className="">
                  INSCRITO
                </Radio.Button>
              )}
            </Radio.Group>
          )}

          {idInstitucion === INSTITUCION.CEPU && (
            <Radio.Group
              className="flex flex-wrap text-center"
              buttonStyle="solid"
              value={dataToPage?.idestado}
              onChange={(e) => showChangeStateConfirm(e.target.value)}
            >
              {rol !== OBJ_ROLES.ORGANIZADOR && (
                <Radio.Button value={ESTADO?.incompleto || ""} className="flex-1">
                  INCOMPLETO
                </Radio.Button>
              )}
              <Radio.Button value={ESTADO?.pendiente || ""} className="flex-1">
                PENDIENTE
              </Radio.Button>
              <Radio.Button value={ESTADO?.observado || ""} className="flex-1">
                OBSERVADO
              </Radio.Button>
              <Radio.Button value={ESTADO?.aceptado || ""} className="flex-1">
                ACEPTADO
              </Radio.Button>

              {(rol === OBJ_ROLES.SUPERADMIN || rol === OBJ_ROLES.ESPECIALISTA_SISTEMAS) && (
                <Radio.Button value={ESTADO?.inscrito || ""} className="flex-1">
                  INSCRITO
                </Radio.Button>
              )}
            </Radio.Group>
          )}

          {idInstitucion === INSTITUCION.VIAC && (
            <Radio.Group
              className="flex flex-wrap text-center"
              buttonStyle="solid"
              value={dataToPage?.idestado}
              onChange={(e) => showChangeStateConfirm(e.target.value)}
            >
              {(rol === OBJ_ROLES.ADMINISTRADOR || rol === OBJ_ROLES.SUPERADMIN) && (
                <Radio.Button value={ESTADO?.incompleto || ""} className="flex-1">
                  INCOMPLETO
                </Radio.Button>
              )}
              <Radio.Button value={ESTADO?.pendiente || ""} className="flex-1">
                PENDIENTE
              </Radio.Button>
              <Radio.Button value={ESTADO?.observado || ""} className="flex-1">
                OBSERVADO
              </Radio.Button>
              <Radio.Button value={ESTADO?.aceptadobiometrico || ""} className="flex-1">
                ACEPTADO_BIOMETRICO
              </Radio.Button>

              {(rol === OBJ_ROLES.ADMINISTRADOR || rol === OBJ_ROLES.SUPERADMIN) && (
                <Radio.Button value={ESTADO?.inscrito || ""} className="flex-1">
                  INSCRITO
                </Radio.Button>
              )}
            </Radio.Group>
          )}

          {idInstitucion === INSTITUCION.SESP && rol !== OBJ_ROLES.SEGUNDA_ESPECIALIDAD && (
            <Radio.Group
              className="flex flex-wrap text-center"
              buttonStyle="solid"
              value={dataToPage?.idestado}
              onChange={(e) => showChangeStateConfirm(e.target.value)}
            >
              <Radio.Button value={ESTADO?.incompleto || ""} className="flex-1">
                INCOMPLETO
              </Radio.Button>
              <Radio.Button value={ESTADO?.pendiente || ""} className="flex-1">
                PENDIENTE
              </Radio.Button>
              <Radio.Button value={ESTADO?.observado || ""} className="flex-1">
                OBSERVADO
              </Radio.Button>
              <Radio.Button value={ESTADO?.inscrito || ""} className="flex-1">
                INSCRITO
              </Radio.Button>
            </Radio.Group>
          )}

          <hr className="my-2" />
          <h2 className="text-md font-bold my-2 text-azul-700">OBSERVACIONES</h2>
          <Form layout="vertical" initialValues={{ remember: true }} onFinish={onSubmit}>
            <Form.Item
              label="Mensaje a postulante  (SI SE LE MOSTRARÁ CUANDO ESTÉ OBSERVADO)"
              name="observacionexterno"
              initialValue={dataToPage?.observacionexterno}
            >
              <Input.TextArea
                rows={3}
                placeholder="Agrege un mensaje directo al postulante respecto a los datos o archivos"
              />
            </Form.Item>

            <Form.Item
              label="Observación local (No se mostrará al postulante)"
              name="observacioninterno"
              initialValue={dataToPage?.observacioninterno}
            >
              <Input.TextArea
                rows={3}
                placeholder="Agrege una observación local del postulante, este no lo podrá leer"
              />
            </Form.Item>

            <Form.Item className="my-2">
              <Button type="primary" block htmlType="submit">
                Guardar cambios
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>

      <Modal
        title="PROCESAR FOTOGRAFÍA"
        visible={isModalVisible}
        width={1000}
        closable
        onCancel={() => setIsModalVisible(false)}
        destroyOnClose
        footer={null}
        maskClosable={false}
      >
        {modalContent}
      </Modal>
    </Fragment>
  );
};

export default Revision;
